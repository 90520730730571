@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

body {
  background-color: #fff !important;
}

a {
  text-decoration: none;
}

body .rdrDayNumber {
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

body .rdrSelected {
  top: 2px;
  bottom: 2px;
  left: 6px;
  right: 6px;
  border-radius: 100%;
}

body .rdrDayActive .rdrDayNumber {
  font-size: 14px;
}

body .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  top: -5px;
  bottom: -5px;
  left: 4px;
  right: 4px;
  border-radius: 100%;
}

body .rdrDayToday .rdrDayNumber span:after {
  background: #0f0190;
}
/*.MuiDrawer-root{*/
/*  max-height: 0px;*/
/*}*/
